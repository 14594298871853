import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'gatsby';

const NavMenu = () => {
  return (
    <Navbar collapseOnSelect expand="lg" className="portfolio-nav">
      <span className="navbrand">
        <Link to="/">Roberto Sanchez</Link>
      </span>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse>
        <Nav className="ml-auto">
          <Link to="/" className="navmenu-link" activeClassName="active">
            Home
          </Link>
          {
            //   <Link to="/construction" className="navmenu-link" activeClassName="active">
            //   About
            // </Link>
            // <Link to="/construction" className="navmenu-link" activeClassName="active">
            //   Resume
            // </Link>
          }
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavMenu;
